import axios from 'axios'

export default {
  state: {
    currentUser: null,
    processing: false,
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
  },
  mutations: {
    setUser (state, payload) {
      state.currentUser = payload
      state.processing = false
    },
    setLogout (state) {
      state.currentUser = null
      state.processing = false
    },
    setProcessing (state, payload) {
      state.processing = payload
    }
  },
  actions: {
    setUser ({ commit }, payload) {
      commit('setUser', payload)
    },

    validateSession () {
      return new Promise((resolve) => {
        axios
          .post('/v1/auth/session')
          .then(() => {
            return resolve(true)
          })
          .catch(e => {
            console.error(e)
            return resolve(false)
          })
      })
    },
    signOut ({ commit }) {
      localStorage.removeItem('user')
      localStorage.removeItem('user-token')
      commit('setLogout')
    }
  }
}
