<template>
  <b-container fluid>
    <b-form @submit.prevent="$emit('submit', joinMeetingObj)">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="4"
        label="Meeting Code"
      >
        <b-form-input
          type="text"
          placeholder="Enter meeting code"
          v-model="joinMeetingObj.meetingCode"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="4"
        label="User Name"
      >
        <b-form-input
          type="text"
          placeholder="Enter user name"
          v-model="joinMeetingObj.userName"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="4"
        label="User Email"
      >
        <b-form-input
          type="email"
          placeholder="Enter user email"
          v-model="joinMeetingObj.userEmail"
          required
        ></b-form-input>
      </b-form-group>
      <hr>
      <b-button
        type='submit'
        block
        variant="primary"
      >
        Join Meeting
      </b-button>
    </b-form>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      joinMeetingObj: {
        userName: "",
        userEmail: "",
        meetingCode: ""
      }
    };
  }
};
</script>