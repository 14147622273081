<template>
  <b-container fluid>
    <b-form @submit.prevent="$emit('submit', joinMeetingObj)">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="4"
        label="Meeting Name"
      >
        <b-form-input
          type="text"
          v-model="joinMeetingObj.meetingName"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="4"
        label="Meeting Password"
      >
        <b-form-input
          type="password"
          v-model="joinMeetingObj.meetingPassword"
          required
        ></b-form-input>
      </b-form-group>
      <hr>
      <b-button
        type='submit'
        block
        variant="primary"
      >
        Join Meeting
      </b-button>
    </b-form>
  </b-container>
</template>
<script>
export default {
  name: "Password-Page",
  data() {
    return {
      joinMeetingObj: {
        meetingName: "",
        meetingPassword: ""
      }
    };
  },
  mounted() {
    const store = this.$goigi.getMeetingStore();
    this.joinMeetingObj.meetingName = store.meeting.meetingName;
  }
};
</script>