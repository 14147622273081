import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import meet from "./modules/meet";
import user from "./modules/user";

export default createStore({
  state: {
    isLoading: false,
    isLoadingText: "",
  },
  mutations: {
    IsLoading(state, payload) {
      state.isLoading = payload.isLoading;
      state.isLoadingText = payload.isLoadingText;
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isLoadingText: (state) => state.isLoadingText,
  },
  actions: {
    IsLoading({ commit }, payload) {
      commit("IsLoading", payload);
    },
  },
  modules: {
    meet,
    user,
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
