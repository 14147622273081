import { createApp } from "vue";
import { configureCompat } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue } from "bootstrap-vue";

import { GoigiConferenceService } from "./plugins/goigi.plugin";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Toaster
import Toasted from "vue-toasted";

// Clipboard Copy
import VueClipboard from "vue-clipboard2";

// Import JQuery
import jQuery from "jquery";

const app = createApp(App);

Object.assign(window, { $: jQuery, jQuery });

VueClipboard.config.autoSetContainer = true; // add this line
app.use(VueClipboard);

app.use(Toasted);

// Make BootstrapVue available throughout your project
app.use(BootstrapVue);
app.use(router);
app.use(store);
configureCompat({
  WATCH_ARRAY: false,
});

// Init Goigi video service plugin
app.prototype.$goigi = new GoigiConferenceService({
  licenseKey: "9e9e36f3-a0fa-40a9-ba49-12c97262589f",
  serverUrl: "https://adgoogly.com:8081",
  proxy: "",
});

app.mount("#app");
