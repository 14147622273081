<template>
  <section
    class="conferenceBody"
    style="background:url('/assets/images/bg-2.jpg') no-repeat; background-size:cover;"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="left-pnl">
            <div class="logo">
              <a href=""><img
                  src="/assets/images/logo.png"
                  height="50px"
                /></a>
            </div>
            <div class="page-text">
              <h2>Goigi Conference Demo</h2>
              <p>Welcome to Goigi video conference</p>
              <div class="join-box">

                <button
                  class="btn btn-host m-2"
                  v-b-modal.modal-1
                >Host Meeting</button>

                <button
                  class="btn btn-join m-2"
                  v-b-modal.modal-2
                >Join Meeting</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1 col-md-6 col-sm-12 col-xs-12">
          <div class="right-pnl">
            <div class="video-screen">
              <img src="/assets/images/home.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <b-modal
      id="modal-1"
      title="Host Meeting"
      hide-footer
      centered
    >
      <new-meeting @submit="startNewMeeting" />
    </b-modal>

    <!-- Join Meeting Modal -->
    <b-modal
      id="modal-2"
      title="Join Meeting"
      hide-footer
      centered
    >
      <join-meeting @submit="joinMeeting" />
    </b-modal>

    <!-- Password Modal -->
    <b-modal
      id="modal-3"
      title="Authentication"
      hide-footer
      centered
    >
      <password @submit="validateMeeting" />
    </b-modal>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import NewMeeting from "@/components/modals/NewMeeting.vue";
import JoinMeeting from "@/components/modals/JoinMeeting.vue";
import Password from "@/components/modals/Password.vue";

export default {
  name: "Home-Page",
  components: {
    "new-meeting": NewMeeting,
    "join-meeting": JoinMeeting,
    password: Password
  },
  mounted() {
    this.$store.dispatch("IsLoading", { isLoading: false, isLoadingText: "" });
  },
  methods: {
    ...mapActions(["setUser"]),
    async startNewMeeting(meetingDetails) {
      try {
        this.$bvModal.hide("modal-1");

        this.$store.dispatch("IsLoading", {
          isLoading: true,
          isLoadingText: "Creating Room..."
        });

        // Create new meeting and get meeting code and server url
        const meetingCode = await this.$goigi.CreateNewMeeting({
          hostId: meetingDetails.hostEmail,
          hostName: meetingDetails.hostName,
          hostEmail: meetingDetails.hostEmail,
          meetingName: meetingDetails.meetingName,
          meetingPassword: meetingDetails.meetingPassword,
          isPasswordEnabled: meetingDetails.isPasswordEnabled,
          meetingType: "OTM"
        });

        this.$store.dispatch("IsLoading", {
          isLoading: false,
          isLoadingText: ""
        });

        /** USE VUEX FOR USER */
        const goigiStore = this.$goigi.getMeetingStore();
        this.setUser({ ...goigiStore.user });

        this.$router.push(`/preview/${meetingCode}`);
      } catch (e) {
        console.error(e);
        this.$store.dispatch("IsLoading", {
          isLoading: false,
          isLoadingText: ""
        });
        this.$toasted.error(e.message, { duration: 2000 });
      }
    },

    async joinMeeting(meetingDetails) {
      try {
        this.$bvModal.hide("modal-2");

        this.$store.dispatch("IsLoading", {
          isLoading: true,
          isLoadingText: "Joining..."
        });

        // Join meeting with meeting code and user details
        const meeting = await this.$goigi.JoinMeeting({
          meetingCode: meetingDetails.meetingCode,
          userId: meetingDetails.userEmail,
          userName: meetingDetails.userName,
          userEmail: meetingDetails.userEmail
        });

        this.$store.dispatch("IsLoading", {
          isLoading: false,
          isLoadingText: ""
        });

        /** USE VUEX FOR USER */
        const goigiStore = this.$goigi.getMeetingStore();
        this.setUser({ ...goigiStore.user });

        // Show password dialog if meeting has password
        if (meeting.isPasswordEnabled) {
          this.$bvModal.show("modal-3");
        } else {
          this.$router.push(`/preview/${meetingDetails.meetingCode}`);
        }
      } catch (e) {
        console.error(e);
        this.$store.dispatch("IsLoading", {
          isLoading: false,
          isLoadingText: ""
        });
        this.$toasted.error(e.message, { duration: 2000 });
      }
    },

    async validateMeeting(meetingDetails) {
      try {
        this.$bvModal.hide("modal-3");

        this.$store.dispatch("IsLoading", {
          isLoading: true,
          isLoadingText: "Please wait..."
        });

        // Join meeting with meeting code and user details
        const meetingCode = await this.$goigi.ValidateMeeting({
          meetingPassword: meetingDetails.meetingPassword
        });

        this.$store.dispatch("IsLoading", {
          isLoading: false,
          isLoadingText: ""
        });

        this.$router.push(`/preview/${meetingCode}`);
      } catch (e) {
        console.error(e);
        this.$store.dispatch("IsLoading", {
          isLoading: false,
          isLoadingText: ""
        });
        this.$toasted.error("Server down", { duration: 2000 });
      }
    }
  }
};
</script>