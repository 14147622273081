/* eslint-disable */
export class GoigiStorageHelper {
    constructor() {
        this.STORAGE_KEY = 'goigi'
        this.STORAGE_MEDIASTREAMS = new Map()

        // Memory Storage
        this.MEM_STORE = {
            sessionId: null,
            sessionRoom: null,
            sessionSettings: null,
            selfVideoElementId: null,
            largeSelfVideoElementId: null,
            defaultCameraId: 'default',
            defaultMicrophoneId: 'default',
            defaultSpeakerId: 'default',
            micInProgress: false,
            webcamInProgress: false,
            shareInProgress: false,
            canChangeWebcam: false,
            chatMessages: [],
            intervals: [],
            peers: new Map(),
            producers: new Map(),
            consumers: new Map(),
            dataProducers: new Map(),
            dataConsumers: new Map(),
            connectionState: null,
            canSendMic: false,
            canSendWebcam: false,
            sessionMuted: false,
            activeUserTyping: '',
            activeSpeakerId: null,
            isVideoTransmissionPaused: false,
            consumerSettings: {
                receiveVideo: true,
                receiveAudio: true
            }
        }

        // Init Local Storage
        if (!this.getLocalStorage()) this.storeLocalStorage({
            settings: {}
        })

        // Init Session Storage
        if (!this.getSessionStorage()) this.RESET_STORE({ user: true, meeting: true })
    }

    storeLocalStorage (value) {
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(value));
    }

    getLocalStorage () {
        const data = JSON.parse(localStorage.getItem(this.STORAGE_KEY))
        return data == null ? undefined : data
    }

    storeSessionStorage (value) {
        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(value));
    }

    getSessionStorage () {
        const data = JSON.parse(sessionStorage.getItem(this.STORAGE_KEY))
        return data == null ? undefined : data
    }

    // Reset to defaults
    RESET_STORE ({ user, meeting }) {

        this.MEM_STORE.intervals.forEach(i => { clearInterval(i) })

        this.storeSessionStorage({
            user: (user) ? {} : this.GET_STORE().user,
            meeting: (meeting) ? {} : this.GET_STORE().meeting
        })
    }

    // Get combined store results
    GET_STORE () {
        const local = this.getLocalStorage()
        const session = this.getSessionStorage()
        return {
            ...(local) ? local : {},
            ...(session) ? session : {}
        }
    }

    // Save settings object
    SAVE_SETTINGS (settings) {
        const obj = {
            ...this.getLocalStorage(),
            settings: { ...this.GET_SETTINGS(), ...settings }
        }

        this.storeLocalStorage(obj)
        return obj.settings
    }

    // Get settings object
    GET_SETTINGS () {
        return this.GET_STORE().settings
    }

    // Set user details
    UPDATE_USER_DETAILS (userObject) {
        const obj = {
            user: {
                ...this.getSessionStorage().user,
                ...userObject
            }
        }

        this.storeSessionStorage({ ...this.getSessionStorage(), ...obj })
        return obj
    }

    // Update connection state
    UPDATE_CONNECTION_STATE (state) {
        this.MEM_STORE.connectionState = state
    }

    // Update peers
    UPDATE_PEERS (payload) {
        const { peer } = payload
        this.MEM_STORE.peers = { ...this.MEM_STORE.peers, [peer.id]: peer }
    }

    // Update capabilities
    UPDATE_MEDIA_CAPABILITIES ({ canSendMic, canSendWebcam }) {
        this.MEM_STORE.canSendMic = canSendMic
        this.MEM_STORE.canSendWebcam = canSendWebcam
    }

    // Add producer
    ADD_PRODUCER (payload) {
        const { producer } = payload
        this.MEM_STORE.producers = { ...this.MEM_STORE.producers, [producer.id]: producer }
    }

    REMOVE_PRODUCER (payload) {
        const { producerId } = payload
        const newState = { ...this.MEM_STORE.producers }
        delete newState[producerId]
        this.MEM_STORE.producers = newState
    }

    SET_PRODUCER_PAUSED (payload) {
        const { producerId } = payload
        const producer = this.MEM_STORE.producers[producerId]
        const newProducer = { ...producer, paused: true }
        this.MEM_STORE.producers = { ...this.MEM_STORE.producers, [producerId]: newProducer }

    }

    SET_PRODUCER_RESUMED (payload) {
        const { producerId } = payload
        const producer = this.MEM_STORE.producers[producerId]
        const newProducer = { ...producer, paused: false }
        this.MEM_STORE.producers = { ...this.MEM_STORE.producers, [producerId]: newProducer }

    }

    SET_PRODUCER_TRACK (payload) {
        const { producerId, track } = payload
        const producer = this.MEM_STORE.producers[producerId]
        const newProducer = { ...producer, track }
        this.MEM_STORE.producers = { ...this.MEM_STORE.producers, [producerId]: newProducer }

    }

    SET_PRODUCER_SCORE (payload) {
        const { producerId, score } = payload
        const producer = this.MEM_STORE.producers[producerId]

        if (!producer) { return }

        const newProducer = { ...producer, score }
        this.MEM_STORE.producers = { ...this.MEM_STORE.producers, [producerId]: newProducer }

    }

    SET_VIDEO_TRANSMISSION_PAUSE_STATE (payload) {
        this.MEM_STORE.isVideoTransmissionPaused = payload
    }


    // Webcam state
    SET_WEBCAM_INFO ({ defaultCameraId, webcamInProgress, canChangeWebcam }) {
        this.MEM_STORE.defaultCameraId = (defaultCameraId) ? defaultCameraId : this.MEM_STORE.defaultCameraId
        this.MEM_STORE.webcamInProgress = (webcamInProgress) ? true : false
        this.MEM_STORE.canChangeWebcam = (canChangeWebcam) ? true : false
    }

    //   Add data producer
    ADD_DATA_PRODUCER (payload) {
        const { dataProducer } = payload
        this.MEM_STORE.dataProducers = { ...this.MEM_STORE.dataProducers, [dataProducer.id]: dataProducer }
    }

    // Remove data producer
    REMOVE_DATA_PRODUCER (payload) {
        const { dataProducerId } = payload
        const newState = { ...this.MEM_STORE.dataProducers }
        delete newState[dataProducerId]
        this.MEM_STORE.dataProducers = newState
    }

    REMOVE_DATA_CONSUMER (payload) {
        const { dataConsumerId, peerId } = payload
        const newState = { ...this.MEM_STORE.dataConsumers }
        delete newState[dataConsumerId]
        this.MEM_STORE.dataConsumers = newState

        // Update in PEERS

        // special case for bot DataConsumer.
        if (!peerId) return

        const peer = this.MEM_STORE.peers[peerId]
        if (!peer) return

        const idx = peer.dataConsumers.indexOf(dataConsumerId)
        if (idx === -1) return

        const newDataConsumers = peer.dataConsumers.slice()
        newDataConsumers.splice(idx, 1)
        const newPeer = { ...peer, dataConsumers: newDataConsumers }
        this.MEM_STORE.peers = { ...this.MEM_STORE.peers, [newPeer.id]: newPeer }
    }

    SET_ROOM_ACTIVE_SPEAKER (payload) {
        const { peerId } = payload
        this.MEM_STORE.session = { ...this.MEM_STORE.session, activeSpeakerId: peerId }
    }

    // Set peer can speak 
    SET_PEER_CAN_SPEAK (payload) {
        const { peerId, value } = payload
        const peer = this.MEM_STORE.peers[peerId]
        if (!peer) return

        const newPeer = { ...peer, isMicEnabled: value }
        this.MEM_STORE.peers = { ...this.MEM_STORE.peers, [newPeer.id]: newPeer }
    }

    // Set peer webcam permission
    SET_PEER_CAM_ENABLED (payload) {
        const { peerId, value } = payload
        const peer = this.MEM_STORE.peers[peerId]
        if (!peer) return

        const newPeer = { ...peer, isCamEnabled: value }
        this.MEM_STORE.peers = { ...this.MEM_STORE.peers, [newPeer.id]: newPeer }
    }

    // Set session mute
    SET_SESSION_MUTED (payload) {
        this.MEM_STORE.sessionMuted = payload
    }

    // Add new consumer
    ADD_CONSUMER (payload) {
        //   UPDATE IN CONSUMERS
        const { consumer, peerId } = payload
        this.MEM_STORE.consumers = { ...this.MEM_STORE.consumers, [consumer.id]: consumer }

        // UPDATE IN PEER CONSUMERS
        const peer = this.MEM_STORE.peers[peerId]
        if (!peer) return
        const newConsumers = [...peer.consumers, consumer.id]
        const newPeer = { ...peer, consumers: newConsumers }
        this.MEM_STORE.peers = { ...this.MEM_STORE.peers, [newPeer.id]: newPeer }
    }

    // Remove consumer
    REMOVE_CONSUMER (payload) {
        //   UPDATE IN CONSUMERS
        const { consumerId, peerId } = payload
        const newState = { ...this.MEM_STORE.consumers }
        delete newState[consumerId]
        this.MEM_STORE.consumers = newState

        // UPDATE IN PEER CONSUMERS
        const peer = this.MEM_STORE.peers[peerId]
        if (!peer) return

        const idx = peer.consumers.indexOf(consumerId)
        if (idx === -1) return

        const newConsumers = peer.consumers.slice()
        newConsumers.splice(idx, 1)
        const newPeer = { ...peer, consumers: newConsumers }
        this.MEM_STORE.peers = { ...this.MEM_STORE.peers, [newPeer.id]: newPeer }
    }

    // Set consumer paused
    SET_CONSUMER_PAUSED (payload) {
        const { consumerId, originator } = payload
        const consumer = this.MEM_STORE.consumers[consumerId]
        let newConsumer
        if (originator === 'local') { newConsumer = { ...consumer, locallyPaused: true } } else { newConsumer = { ...consumer, remotelyPaused: true } }
        this.MEM_STORE.consumers = { ...this.MEM_STORE.consumers, [consumerId]: newConsumer }
    }

    // Set consumer resume
    SET_CONSUMER_RESUMED (payload) {
        const { consumerId, originator } = payload
        const consumer = this.MEM_STORE.consumers[consumerId]
        let newConsumer
        if (originator === 'local') { newConsumer = { ...consumer, locallyPaused: false } } else { newConsumer = { ...consumer, remotelyPaused: false } }
        this.MEM_STORE.consumers = { ...this.MEM_STORE.consumers, [consumerId]: newConsumer }
    }

    SET_CONSUMER_CURRENT_LAYERS (payload) {
        const { consumerId, spatialLayer, temporalLayer } = payload
        const consumer = this.MEM_STORE.consumers[consumerId]
        const newConsumer =
        {
            ...consumer,
            currentSpatialLayer: spatialLayer,
            currentTemporalLayer: temporalLayer
        }
        this.MEM_STORE.consumers = { ...this.MEM_STORE.consumers, [consumerId]: newConsumer }
    }
    SET_CONSUMER_PREFERRED_LAYERS (payload) {
        const { consumerId, spatialLayer, temporalLayer } = payload
        const consumer = this.MEM_STORE.consumers[consumerId]
        const newConsumer =
        {
            ...consumer,
            preferredSpatialLayer: spatialLayer,
            preferredTemporalLayer: temporalLayer
        }

        this.MEM_STORE.consumers = { ...this.MEM_STORE.consumers, [consumerId]: newConsumer }
    }
    SET_CONSUMER_PRIORITY (payload) {
        const { consumerId, priority } = payload
        const consumer = this.MEM_STORE.consumers[consumerId]
        const newConsumer = { ...consumer, priority }
        this.MEM_STORE.consumers = { ...this.MEM_STORE.consumers, [consumerId]: newConsumer }
    }

    SET_CONSUMER_TRACK (payload) {
        const { consumerId, track } = payload
        const consumer = this.MEM_STORE.consumers[consumerId]
        const newConsumer = { ...consumer, track }
        this.MEM_STORE.consumers = { ...this.MEM_STORE.consumers, [consumerId]: newConsumer }
    }
    SET_CONSUMER_SCORE (payload) {
        const { consumerId, score } = payload
        const consumer = this.MEM_STORE.consumers[consumerId]
        if (!consumer) return
        const newConsumer = { ...consumer, score }
        this.MEM_STORE.consumers = { ...this.MEM_STORE.consumers, [consumerId]: newConsumer }
    }
    SET_CONSUMER_SETTINGS (payload) {
        this.MEM_STORE.consumerSettings = { ...this.MEM_STORE.consumerSettings, ...payload }
    }


    // Set active user typing
    SET_ACTIVE_USER_TYPING (payload) {
        this.MEM_STORE.session.activeUserTyping = payload
    }


    // Set hand raised
    SET_PEER_HAND_RAISED (payload) {
        const { peerId, value } = payload
        const peer = this.MEM_STORE.peers[peerId]
        if (!peer) return

        const newPeer = { ...peer, handRaised: value }
        this.MEM_STORE.peers = { ...this.MEM_STORE.peers, [newPeer.id]: newPeer }
    }

    ADD_PEER (payload) {
        const { peer } = payload
        this.MEM_STORE.peers = { ...this.MEM_STORE.peers, [peer.id]: peer }
    }

    REMOVE_PEER (payload) {
        const { peerId } = payload
        delete this.MEM_STORE.peers[peerId]
    }

    SET_MICROPHONE_IN_PROGRESS (payload) {
        this.MEM_STORE.micInProgress = payload
    }

    SET_DEFAULT_CAMERA_ID (payload) {
        const { deviceId } = payload
        this.MEM_STORE.defaultCameraId = deviceId
    }

    SET_DEFAULT_MICROPHONE_ID (payload) {
        const { deviceId } = payload
        this.MEM_STORE.defaultMicrophoneId = deviceId
    }
    SET_DEFAULT_SPEAKER_ID (payload) {
        const { deviceId } = payload
        this.MEM_STORE.defaultSpeakerId = deviceId
    }
    // ===================

    // Update meeting details
    UPDATE_MEETING_DETAILS (payload) {
        const obj = {
            meeting: {
                ...this.getSessionStorage().meeting,
                ...payload
            }
        }

        this.storeSessionStorage({ ...this.getSessionStorage(), ...obj })
        return obj
    }

}

// export default {
//     state: {
//         meet: {
//             session: {
//                 sessionId: null,
//                 sessionName: null,
//                 isSessionHost: false,
//                 sessionRole: null,
//                 sessionHostId: null,
//                 sessionURL: null,
//                 state: 'new',
//                 activeSpeakerId: null,
//                 activeUserTyping: null,
//                 sessionMuted: false,
//                 isPasswordProtected: false,
//             },
//             self: {
//                 id: null,
//                 userName: null,
//                 device: null,
//                 canSendMic: false,
//                 canSendWebcam: false,
//                 canChangeWebcam: false,
//                 micInProgress: false,
//                 webcamInProgress: false,
//                 shareInProgress: false,
//                 audioOnly: false,
//                 audioOnlyInProgress: false,
//                 audioMuted: false,
//                 restartIceInProgress: false,
//                 defaultCameraId: null,
//                 defaultMicrophoneId: 'default',
//                 defaultSpeakerId: 'default',
//                 recordingEnabled: false,
//                 isVideoTransmissionPaused: false,
//                 deviceInitSettings: {
//                     enableCamera: true,
//                     enableMicrophone: true
//                 },
//                 consumerSettings: {
//                     receiveVideo: true,
//                     receiveAudio: true
//                 }
//             },
//             producers: new Map(),
//             dataProducers: new Map(),
//             peers: new Map(),
//             consumers: new Map(),
//             dataConsumers: new Map(),
//             chatMessages: []
//         }
//     },
//     getters: {
//         meet: state => state.meet
//     },
//     mutations: {
//         // ROOM MUTATIONS
//         SET_ROOM_URL: (state, payload) => {
//             const { sessionId, sessionRole, sessionName, isPasswordProtected, isSessionHost, sessionURL } = payload
//             state.meet.session = { ...state.meet.session, sessionId, sessionRole, sessionName, isPasswordProtected, isSessionHost, sessionURL }
//         },


//         SET_ROOM_ACTIVE_SPEAKER: (state, payload) => {
//             const { peerId } = payload
//             state.meet.session = { ...state.meet.session, activeSpeakerId: peerId }
//         },
//         SET_SESSION_MUTED: (state, payload) => {
//             state.meet.session = { ...state.meet.session, sessionMuted: payload }
//         },

//         //   ME MUTATIONS
//         SET_ME: (state, payload) => {
//             const { peerId, userName, device } = payload
//             state.meet.self = { ...state.meet.self, id: peerId, userName, device }
//         },
//         SET_MEDIA_CAPABILITIES: (state, payload) => {
//             const { canSendMic, canSendWebcam } = payload
//             state.meet.self = { ...state.meet.self, canSendMic, canSendWebcam }
//         },
//         SET_CAN_CHANGE_WEBCAM: (state, payload) => {
//             const canChangeWebcam = payload
//             state.meet.self = { ...state.meet.self, canChangeWebcam }
//         },

//         SET_WEBCAM_IN_PROGRESS: (state, payload) => {
//             state.meet.self = { ...state.meet.self, webcamInProgress: payload }
//         },
//         SET_SHARE_IN_PROGRESS: (state, payload) => {
//             state.meet.self = { ...state.meet.self, shareInProgress: payload }
//         },
//         SET_AUDIO_ONLY_STATE: (state, payload) => {
//             const { enabled } = payload
//             state.meet.self = { ...state.meet.self, audioOnly: enabled }
//         },
//         SET_AUDIO_ONLY_IN_PROGRESS: (state, payload) => {
//             const { flag } = payload
//             state.meet.self = { ...state.meet.self, audioOnlyInProgress: flag }
//         },
//         SET_AUDIO_MUTED_STATE: (state, payload) => {
//             const { enabled } = payload
//             state.meet.self = { ...state.meet.self, audioMuted: enabled }
//         },
//         SET_RESTART_ICE_IN_PROGRESS: (state, payload) => {
//             const { flag } = payload
//             state.meet.self = { ...state.meet.self, restartIceInProgress: flag }
//         },



//         SET_RECORDER_STATUS: (state, payload) => {
//             state.meet.self = { ...state.meet.self, recordingEnabled: payload }
//         },
//         SET_DEVICE_INIT_SETTINGS: (state, payload) => {
//             state.meet.self = { ...state.meet.self, deviceInitSettings: payload }
//         },




//         //   DATA PRODUCER MUTATIONS
//         ADD_DATA_PRODUCER: (state, payload) => {
//             const { dataProducer } = payload
//             state.meet.dataProducers = { ...state.meet.dataProducers, [dataProducer.id]: dataProducer }
//         },
//         REMOVE_DATA_PRODUCER: (state, payload) => {
//             const { dataProducerId } = payload
//             const newState = { ...state.meet.dataProducers }
//             delete newState[dataProducerId]
//             state.meet.dataProducers = newState
//         },

//         //   PEERS MUTATIONS
//         ADD_PEER: (state, payload) => {
//             const { peer } = payload
//             state.meet.peers = { ...state.meet.peers, [peer.id]: peer }
//         },
//         REMOVE_PEER: (state, payload) => {
//             const { peerId } = payload
//             const newState = { ...state.meet.peers }
//             delete newState[peerId]
//             state.meet.peers = newState
//         },
//         SET_PEER_HAND_RAISED: (state, payload) => {
//             const { peerId, value } = payload
//             const peer = state.meet.peers[peerId]
//             if (!peer) return

//             const newPeer = { ...peer, handRaised: value }
//             state.meet.peers = { ...state.meet.peers, [newPeer.id]: newPeer }
//         },
//         SET_PEER_CAN_SPEAK: (state, payload) => {
//             const { peerId, value } = payload
//             const peer = state.meet.peers[peerId]
//             if (!peer) return

//             const newPeer = { ...peer, isMicEnabled: value }
//             state.meet.peers = { ...state.meet.peers, [newPeer.id]: newPeer }
//         },
//         SET_PEER_CAM_ENABLED: (state, payload) => {
//             const { peerId, value } = payload
//             const peer = state.meet.peers[peerId]
//             if (!peer) return

//             const newPeer = { ...peer, isCamEnabled: value }
//             state.meet.peers = { ...state.meet.peers, [newPeer.id]: newPeer }
//         },
//         SET_PEER_DISPLAY_NAME: (state, payload) => {
//             const { userName, peerId } = payload
//             const peer = state.meet.peers[peerId]

//             if (!peer) return

//             const newPeer = { ...peer, userName }
//             state.meet.peers = { ...state.meet.peers, [newPeer.id]: newPeer }
//         },

//         //   CONSUMER MUTATIONS
//         ADD_CONSUMER: (state, payload) => {
//             //   UPDATE IN CONSUMERS
//             const { consumer, peerId } = payload
//             state.meet.consumers = { ...state.meet.consumers, [consumer.id]: consumer }

//             // UPDATE IN PEER CONSUMERS
//             const peer = state.meet.peers[peerId]
//             if (!peer) return
//             const newConsumers = [...peer.consumers, consumer.id]
//             const newPeer = { ...peer, consumers: newConsumers }
//             state.meet.peers = { ...state.meet.peers, [newPeer.id]: newPeer }
//         },
//         REMOVE_CONSUMER: (state, payload) => {
//             //   UPDATE IN CONSUMERS
//             const { consumerId, peerId } = payload
//             const newState = { ...state.meet.consumers }
//             delete newState[consumerId]
//             state.meet.consumers = newState

//             // UPDATE IN PEER CONSUMERS
//             const peer = state.meet.peers[peerId]
//             if (!peer) return

//             const idx = peer.consumers.indexOf(consumerId)
//             if (idx === -1) return

//             const newConsumers = peer.consumers.slice()
//             newConsumers.splice(idx, 1)
//             const newPeer = { ...peer, consumers: newConsumers }
//             state.meet.peers = { ...state.meet.peers, [newPeer.id]: newPeer }
//         },
//         SET_CONSUMER_PAUSED: (state, payload) => {
//             const { consumerId, originator } = payload
//             const consumer = state.meet.consumers[consumerId]
//             let newConsumer
//             if (originator === 'local') { newConsumer = { ...consumer, locallyPaused: true } } else { newConsumer = { ...consumer, remotelyPaused: true } }
//             state.meet.consumers = { ...state.meet.consumers, [consumerId]: newConsumer }
//         },
//         SET_CONSUMER_RESUMED: (state, payload) => {
//             const { consumerId, originator } = payload
//             const consumer = state.meet.consumers[consumerId]
//             let newConsumer
//             if (originator === 'local') { newConsumer = { ...consumer, locallyPaused: false } } else { newConsumer = { ...consumer, remotelyPaused: false } }
//             state.meet.consumers = { ...state.meet.consumers, [consumerId]: newConsumer }
//         },
//         SET_CONSUMER_PREFERRED_LAYERS: (state, payload) => {
//             const { consumerId, spatialLayer, temporalLayer } = payload
//             const consumer = state.meet.consumers[consumerId]
//             const newConsumer =
//             {
//                 ...consumer,
//                 preferredSpatialLayer: spatialLayer,
//                 preferredTemporalLayer: temporalLayer
//             }

//             state.meet.consumers = { ...state.meet.consumers, [consumerId]: newConsumer }
//         },
//         SET_CONSUMER_PRIORITY: (state, payload) => {
//             const { consumerId, priority } = payload
//             const consumer = state.meet.consumers[consumerId]
//             const newConsumer = { ...consumer, priority }
//             state.meet.consumers = { ...state.meet.consumers, [consumerId]: newConsumer }
//         },
//         SET_CONSUMER_TRACK: (state, payload) => {
//             const { consumerId, track } = payload
//             const consumer = state.meet.consumers[consumerId]
//             const newConsumer = { ...consumer, track }
//             state.meet.consumers = { ...state.meet.consumers, [consumerId]: newConsumer }
//         },
//         REMOVE_DATA_CONSUMER: (state, payload) => {
//             const { dataConsumerId, peerId } = payload
//             const newState = { ...state.meet.dataConsumers }
//             delete newState[dataConsumerId]
//             state.meet.dataConsumers = newState

//             // Update in PEERS

//             // special case for bot DataConsumer.
//             if (!peerId) return

//             const peer = state.meet.peers[peerId]
//             if (!peer) return

//             const idx = peer.dataConsumers.indexOf(dataConsumerId)
//             if (idx === -1) return

//             const newDataConsumers = peer.dataConsumers.slice()
//             newDataConsumers.splice(idx, 1)
//             const newPeer = { ...peer, dataConsumers: newDataConsumers }
//             state.meet.peers = { ...state.meet.peers, [newPeer.id]: newPeer }
//         },

//         // CHAT MESSAGE MUTATION
//         INSERT_CHATMESSAGE: (state, payload) => {
//             state.meet.chatMessages.push(payload)
//         },
//         MARK_CHATMESSAGE_AS_READ: (state, payload) => {
//             let msg = state.meet.chatMessages.find(msg => msg.ID === payload.ID)
//             if (msg) msg.isRead = true
//         },
//         MARK_CHATMESSAGE_AS_UNREAD: (state, payload) => {
//             let msg = state.meet.chatMessages.find(msg => msg.ID === payload.ID)
//             if (msg) msg.isRead = false
//         },
//         SET_ACTIVE_USER_TYPING: (state, payload) => {
//             state.meet.session.activeUserTyping = payload
//         },

//         RESET_RTCP: (state) => {
//             state.meet = {
//                 session: {
//                     sessionId: null,
//                     sessionRole: null,
//                     sessionHostId: null,
//                     state: 'new',
//                     activeSpeakerId: null,
//                     activeUserTyping: null,
//                     sessionMuted: false
//                 },
//                 self: {
//                     id: null,
//                     userName: null,
//                     device: null,
//                     canSendMic: false,
//                     canSendWebcam: false,
//                     canChangeWebcam: false,
//                     micInProgress: false,
//                     webcamInProgress: false,
//                     shareInProgress: false,
//                     audioOnly: false,
//                     audioOnlyInProgress: false,
//                     audioMuted: false,
//                     restartIceInProgress: false,
//                     defaultCameraId: null,
//                     defaultMicrophoneId: 'default',
//                     defaultSpeakerId: 'default',
//                     recordingEnabled: false,
//                     isVideoTransmissionPaused: false,
//                     deviceInitSettings: state.meet.self.deviceInitSettings,
//                     consumerSettings: {
//                         receiveVideo: true,
//                         receiveAudio: true
//                     }
//                 },
//                 producers: new Map(),
//                 dataProducers: new Map(),
//                 peers: new Map(),
//                 consumers: new Map(),
//                 dataConsumers: new Map(),
//                 chatMessages: []
//             }
//         }
//     },
//     actions: { /**
//         * ROOM ACTIONS
//         */
//         SET_ROOM_URL: ({ commit }, payload) => {
//             commit('SET_ROOM_URL', payload)
//         },
//         SET_ROOM_HOST_ID: ({ commit }, payload) => {
//             commit('SET_ROOM_HOST_ID', payload)
//         },
//         SET_ROOM_STATE: ({ commit }, payload) => {
//             commit('SET_ROOM_STATE', payload)
//         },
//         SET_ROOM_ACTIVE_SPEAKER: ({ commit }, payload) => {
//             commit('SET_ROOM_ACTIVE_SPEAKER', payload)
//         },
//         SET_SESSION_MUTED: ({ commit }, payload) => {
//             commit('SET_SESSION_MUTED', payload)
//         },

//         /** SELF ACTIONS */
//         SET_ME: ({ commit }, payload) => {
//             commit('SET_ME', payload)
//         },
//         SET_MEDIA_CAPABILITIES: ({ commit }, payload) => {
//             commit('SET_MEDIA_CAPABILITIES', payload)
//         },
//         SET_CAN_CHANGE_WEBCAM: ({ commit }, payload) => {
//             commit('SET_CAN_CHANGE_WEBCAM', payload)
//         },
//         SET_MICROPHONE_IN_PROGRESS: ({ commit }, payload) => {
//             commit('SET_MICROPHONE_IN_PROGRESS', payload)
//         },
//         SET_WEBCAM_IN_PROGRESS: ({ commit }, payload) => {
//             commit('SET_WEBCAM_IN_PROGRESS', payload)
//         },
//         SET_SHARE_IN_PROGRESS: ({ commit }, payload) => {
//             commit('SET_SHARE_IN_PROGRESS', payload)
//         },
//         SET_AUDIO_ONLY_STATE: ({ commit }, payload) => {
//             commit('SET_AUDIO_ONLY_STATE', payload)
//         },
//         SET_AUDIO_ONLY_IN_PROGRESS: ({ commit }, payload) => {
//             commit('SET_AUDIO_ONLY_IN_PROGRESS', payload)
//         },
//         SET_AUDIO_MUTED_STATE: ({ commit }, payload) => {
//             commit('SET_AUDIO_MUTED_STATE', payload)
//         },
//         SET_RESTART_ICE_IN_PROGRESS: ({ commit }, payload) => {
//             commit('SET_RESTART_ICE_IN_PROGRESS', payload)
//         },
//         SET_DEFAULT_CAMERA_ID: ({ commit }, payload) => {
//             commit('SET_DEFAULT_CAMERA_ID', payload)
//         },
//         SET_DEFAULT_MICROPHONE_ID: ({ commit }, payload) => {
//             commit('SET_DEFAULT_MICROPHONE_ID', payload)
//         },
//         SET_DEFAULT_SPEAKER_ID: ({ commit }, payload) => {
//             commit('SET_DEFAULT_SPEAKER_ID', payload)
//         },
//         SET_RECORDER_STATUS: ({ commit }, payload) => {
//             commit('SET_RECORDER_STATUS', payload)
//         },
//         SET_DEVICE_INIT_SETTINGS: ({ commit }, payload) => {
//             commit('SET_DEVICE_INIT_SETTINGS', payload)
//         },
//         SET_CONSUMER_SETTINGS: ({ commit }, payload) => {
//             commit('SET_CONSUMER_SETTINGS', payload)
//         },
//         SET_VIDEO_TRANSMISSION_PAUSE_STATE: ({ commit }, payload) => {
//             commit('SET_VIDEO_TRANSMISSION_PAUSE_STATE', payload)
//         },

//         /** PRODUCER ACTIONS */
//         ADD_PRODUCER: ({ commit }, payload) => {
//             commit('ADD_PRODUCER', payload)
//         },
//         REMOVE_PRODUCER: ({ commit }, payload) => {
//             commit('REMOVE_PRODUCER', payload)
//         },
//         SET_PRODUCER_PAUSED: ({ commit }, payload) => {
//             commit('SET_PRODUCER_PAUSED', payload)
//         },
//         SET_PRODUCER_RESUMED: ({ commit }, payload) => {
//             commit('SET_PRODUCER_RESUMED', payload)
//         },
//         SET_PRODUCER_TRACK: ({ commit }, payload) => {
//             commit('SET_PRODUCER_TRACK', payload)
//         },
//         SET_PRODUCER_SCORE: ({ commit }, payload) => {
//             commit('SET_PRODUCER_SCORE', payload)
//         },

//         /** DATA PRODUCER ACTIONS */
//         ADD_DATA_PRODUCER: ({ commit }, payload) => {
//             commit('ADD_DATA_PRODUCER', payload)
//         },
//         REMOVE_DATA_PRODUCER: ({ commit }, payload) => {
//             commit('REMOVE_DATA_PRODUCER', payload)
//         },

//         /** PEER ACTIONS */
//         ADD_PEER: ({ commit }, payload) => {
//             commit('ADD_PEER', payload)
//         },
//         REMOVE_PEER: ({ commit }, payload) => {
//             commit('REMOVE_PEER', payload)
//         },
//         SET_PEER_HAND_RAISED: ({ commit }, payload) => {
//             commit('SET_PEER_HAND_RAISED', payload)
//         },
//         SET_PEER_CAN_SPEAK: ({ commit }, payload) => {
//             commit('SET_PEER_CAN_SPEAK', payload)
//         },
//         SET_PEER_CAM_ENABLED: ({ commit }, payload) => {
//             commit('SET_PEER_CAM_ENABLED', payload)
//         },
//         SET_PEER_DISPLAY_NAME: ({ commit }, payload) => {
//             commit('SET_PEER_DISPLAY_NAME', payload)
//         },

//         /** CONSUMER ACTIONS */
//         ADD_CONSUMER: ({ commit }, payload) => {
//             commit('ADD_CONSUMER', payload)
//         },
//         REMOVE_CONSUMER: ({ commit }, payload) => {
//             commit('REMOVE_CONSUMER', payload)
//         },
//         SET_CONSUMER_PAUSED: ({ commit }, payload) => {
//             commit('SET_CONSUMER_PAUSED', payload)
//         },
//         SET_CONSUMER_RESUMED: ({ commit }, payload) => {
//             commit('SET_CONSUMER_RESUMED', payload)
//         },
//         SET_CONSUMER_CURRENT_LAYERS: ({ commit }, payload) => {
//             commit('SET_CONSUMER_CURRENT_LAYERS', payload)
//         },
//         SET_CONSUMER_PREFERRED_LAYERS: ({ commit }, payload) => {
//             commit('SET_CONSUMER_PREFERRED_LAYERS', payload)
//         },
//         SET_CONSUMER_PRIORITY: ({ commit }, payload) => {
//             commit('SET_CONSUMER_PRIORITY', payload)
//         },
//         SET_CONSUMER_TRACK: ({ commit }, payload) => {
//             commit('SET_CONSUMER_TRACK', payload)
//         },
//         SET_CONSUMER_SCORE: ({ commit }, payload) => {
//             commit('SET_CONSUMER_SCORE', payload)
//         },

//         /** DATA CONSUMER ACTIONS */
//         ADD_DATA_CONSUMER: ({ commit }, payload) => {
//             commit('ADD_DATA_CONSUMER', payload)
//         },
//         REMOVE_DATA_CONSUMER: ({ commit }, payload) => {
//             commit('REMOVE_DATA_CONSUMER', payload)
//         },

//         /** CHAT MESSAGES */
//         INSERT_CHATMESSAGE: ({ commit }, payload) => {
//             commit('INSERT_CHATMESSAGE', payload)
//         },
//         MARK_CHATMESSAGE_AS_READ: ({ commit }, payload) => {
//             commit('MARK_CHATMESSAGE_AS_READ', payload)
//         },
//         SET_ACTIVE_USER_TYPING: ({ commit }, payload) => {
//             commit('SET_ACTIVE_USER_TYPING', payload)
//         },

//         /** CLEANING PROCESS */
//         RESET_RTCP: ({ commit }) => {
//             commit('RESET_RTCP')
//         }
//     }
// }
