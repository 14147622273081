<template>
  <div id="app">

    <!-- Loader -->
    <div v-if="isLoading">
      <div class="overlay"></div>
      <div class="loader text-center">
        <img
          src="/assets/images/loader.svg"
          width="85"
        />
        <h5 class="mt-3 text-primary">{{ loaderText }}</h5>
      </div>
    </div>

    <router-view />
  </div>
</template>
<script>
export default {
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
    loaderText() {
      return this.$store.getters.isLoadingText;
    }
  }
};
</script>
<style>
@font-face {
  font-family: "Product Sans";
  font-style: normal;
  src: local("Product Sans"), local("ProductSans"),
    url("../public/assets/fonts/product-sans.woff2") format("woff2"),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format("woff2");
}

body {
  font-family: "Product Sans" !important;
}

  .loader {
    position: absolute;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10000;
    background: black;
    opacity: 0.8;
  }

p {
  font-size: 14px;
}
.conferenceBody {
  height: 100vh;
  position: relative;
  padding: 30px;
  background: #2b2b2b;
}
.videoimg {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  border-radius: 10px;
  background-color: #0000008c;
  box-shadow: 0 0 15px #00000073;
  position: relative;
  background-size: contain;
}
.conference-btnlist {
  font-size: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
  opacity: 0;
  transition: all 0.2s;
  z-index: 99;
}
.conference-btnlist.result_hover {
  bottom: 10px;
  opacity: 1;
}
.conference-btnlist:hover {
  opacity: 1;
  bottom: 10px;
}
.conference-btnlist ul {
  display: inline-block;
  padding: 8px 5px 5px;
  margin: 0;
  background: #000000a1;
  border-radius: 10px;
}
.conference-btnlist ul li {
  display: inline-block;
  margin: 0 2px;
  min-width: 55px;
  text-align: center;
  line-height: 14px;
}
.conference-btnlist ul li button {
  display: block;
  text-decoration: none;
  width: 44px;
  height: 44px;
  line-height: 45px;
  background: #0ca2f9;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  box-shadow: 0 0 0px #0000006b;
  transition: all 0.3s;
  margin: auto;
  position: relative;
  border: none;
}

.conference-btnlist ul li button:hover {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  background: #0c76f9;
}
@-webkit-keyframes swing {
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes swing {
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
.conference-btnlist ul li button.endcallicon,
.conference-btnlist ul li button.endcallicon:hover {
  background: #f51313;
}
.conference-btnlist ul li span {
  font-weight: 700;
  color: #fff;
  font-size: 10px;
}
.conference-btnlist .videoplayicon.stop,
.conference-btnlist .microphoneicon.stop {
  background: #0c76f9;
}
.conference-btnlist .videoplayicon.stop:after,
.conference-btnlist .microphoneicon.stop:after {
  background: url(../public/assets/images/stoparrow.png);
  width: 44px;
  height: 44px;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  z-index: 1;
}
.btn-primary {
  background: #0ca2f9;
  border-color: #0ca2f9;
}
.modal .close {
  font-size: 22px;
  outline: none;
}
.modal .close:focus {
  outline: none;
}
/***********/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}
.chatHearder .closebtn {
  position: absolute;
  top: 12px;
  right: 15px;
  margin-left: 50px;
  font-size: 20px;
  color: #fff;
}
.chatHearder .closebtn:hover,
.chatHearder .closebtn:focus {
  outline: none;
  text-decoration: none;
}
#main {
  transition: margin-right 0.5s;
  padding: 16px;
}
.chatHearder {
  position: relative;
  width: 320px;
  padding: 15px;
  background: #003e8a;
  background: rgb(12, 118, 249);
  background: -moz-linear-gradient(
    left,
    rgba(12, 118, 249, 1) 0%,
    rgba(12, 162, 249, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(12, 118, 249, 1) 0%,
    rgba(12, 162, 249, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(12, 118, 249, 1) 0%,
    rgba(12, 162, 249, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c76f9', endColorstr='#0ca2f9',GradientType=1 );
}
.chatHearder h2 {
  font-size: 18px;
  margin: 0;
  color: #fff;
  font-weight: 400;
}
.chatHearder h2 i {
  position: relative;
  top: 2px;
  padding-right: 4px;
}
.chatfooter {
  position: absolute;
  bottom: 0;
  padding: 15px;
  width: 320px;
  background: #fff;
  box-shadow: 0 0 10px #00000029;
}
.replychat {
  position: relative;
}
.replychat textarea {
  height: 50px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 13px;
  line-height: 18px;
  color: #262626;
  padding-right: 60px;
  resize: none;
}
.replychat textarea:focus {
  border-color: #0ca2f9;
  outline: none;
}
.replychat .msgSend {
  position: absolute;
  right: 6px;
  bottom: 15px;
  width: 30px;
  height: 30px;
  background: #0ca2f9;
  color: #fff;
  border: 0;
  border-radius: 50%;
  font-size: 16px;
  outline: none;
}
.replychat .fileupload {
  position: absolute;
  right: 75px;
  bottom: 15px;
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  color: #0ca2f9;
  border: 0;
  border-radius: 50%;
  font-size: 16px;
  outline: none;
}
.replychat .fileupload form {
  margin: 50px;
  font-family: "Roboto", sans-serif;
}
.replychat .fileupload input {
  display: none;
}
.replychat .fileupload label {
  cursor: pointer;
  padding: 4px 9px;
}

.replychat .emoji {
  position: absolute;
  right: 40px;
  bottom: 15px;
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  color: #0ca2f9;
  border: 0;
  border-radius: 50%;
  font-size: 16px;
  outline: none;
}
.replychat .emoji form {
  margin: 50px;
  font-family: "Roboto", sans-serif;
}
.replychat .emoji input {
  display: none;
}
.replychat .emoji label {
  cursor: pointer;
  padding: 4px 9px;
}
.chatbody {
  padding: 15px;
  height: calc(100vh - 130px);
  overflow: auto;
  width: 320px;
  background: #f7f7f7;
}
.messaging {
  padding: 0;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: -webkit-min-content;
  min-height: min-content;
  width: 100%;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.messaging li {
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: -webkit-fit-content;
  width: fit-content;
  display: block;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  margin: 5px 0;
  margin-right: 15px;
  background: rgb(12, 118, 249);
  background: -moz-linear-gradient(
    left,
    rgba(12, 118, 249, 1) 0%,
    rgba(12, 162, 249, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(12, 118, 249, 1) 0%,
    rgba(12, 162, 249, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(12, 118, 249, 1) 0%,
    rgba(12, 162, 249, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c76f9', endColorstr='#0ca2f9',GradientType=1 );
}
.messaging li.yourself {
  -ms-flex-item-align: end;
  align-self: flex-end;
  background: #fff;
  color: #262626;
  box-shadow: 0 0 8px #cccccc96;
  margin-right: 0;
  margin-left: 15px;
}
.messaging li p {
  margin: 0;
  font-size: 13px;
}
.messaging li p span {
  font-size: 11px;
  margin-bottom: 3px;
  display: inline-block;
  margin-right: 6px;
  opacity: 0.75;
}
.topbox {
  position: absolute;
  z-index: 99;
  right: 0px;
  top: 0;
  background: #fff;
  padding: 8px 15px;
  border-radius: 0 0 0px 10px;
  box-shadow: 0 0 10px #0000004a;
}
.topbox ul {
  display: block;
  padding: 0;
  margin: 0;
}
.topbox ul li {
  display: inline-block;
  padding: 0px 12px;
  font-size: 16px;
  color: #666;
}
.topbox ul li a {
  display: block;
  color: #666;
  text-decoration: none;
  position: relative;
}
.topbox ul li a span {
  position: absolute;
  top: -4px;
  font-size: 10px;
  right: -18px;
  width: 18px;
  color: #525252;
  text-align: left;
}
.topbox ul li a.gridview {
  color: #f51313;
}
.streamusername {
  position: absolute;
  bottom: 35px;
  left: 15px;
  background: #00000073;
  color: #fff;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 13px;
}
.sidelist-member {
  width: 170px;
  position: absolute;
  right: 22px;
  bottom: 8px;
}
.memberbox {
  background-color: #0e0e0e;
  position: relative;
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  min-height: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
.headseticn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.memberbox .streamusername {
  bottom: 8px;
  left: 8px;
  font-size: 12px;
}
.boxsettings {
  position: absolute;
  top: 55px;
  width: 250px;
  background: #fff;
  z-index: 99;
  right: 15px;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 10px #0000004a;
  display: none;
  -webkit-animation-name: cssAnimation;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
}
.boxsettings:before {
  width: 0;
  height: 0;
  border-width: 0 10px 13px 10px;
  border-color: transparent transparent #ffffff transparent;
  border-style: solid;
  content: "";
  position: absolute;
  top: -13px;
  right: 11px;
}
.boxsettings.open {
  display: block;
}
.seeting-top ul {
  padding: 0;
  margin: 0;
  text-align: left;
}
.seeting-top ul li {
  display: block;
  margin-bottom: 5px;
}
.seeting-top ul li:last-child {
  margin-bottom: 0;
}
.seeting-top ul li a {
  font-size: 13px;
  color: #737373;
  line-height: 30px;
  text-decoration: none;
  transition: all 0.3s;
}
.seeting-top ul li a:hover {
  color: #000;
}
.seeting-top ul li a i {
  width: 26px;
  height: 26px;
  background: #3e3e3e;
  margin-right: 6px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  padding: 0;
}
.seeting-top ul li a .flaticon-refresh {
  line-height: 30px;
}

@-webkit-keyframes cssAnimation {
  from {
    -webkit-transform: rotate(0deg) scale(0.9) skew(0deg) translate(0px);
  }
  to {
    -webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(0px);
  }
}
.seeting-bottom {
  padding-top: 15px;
}
.seeting-bottom h3 {
  font-size: 13px;
  font-weight: 400;
  color: #737373;
  position: relative;
}
.seeting-bottom h3:after {
  position: absolute;
  width: 100%;
  left: 0;
  height: 1px;
  content: "";
  background: #ccc;
  z-index: 0;
  top: 8px;
}
.seeting-bottom h3 span {
  position: relative;
  background: #fff;
  padding: 2px 8px;
  z-index: 1;
}
.seeting-bottom ul {
  padding: 0;
  margin: 0;
  text-align: center;
}
.seeting-bottom ul li {
  display: inline-block;
  width: 32%;
}
.seeting-bottom ul li img {
  max-width: 100%;
}
.seeting-bottom ul li a {
  display: block;
  padding: 10px;
}
.seeting-bottom ul li a img {
  opacity: 0.7;
  transition: all 0.3s;
}
.seeting-bottom ul li a:hover img {
  opacity: 1;
}
.seeting-bottom ul li a.active img {
  opacity: 1;
}
.videolistfortwo {
  height: 100%;
}
.videolistfortwo ul {
  height: 100%;
  display: block;
  padding: 0;
  margin: 0;
  font-size: 0;
}
.videolistfortwo ul li {
  height: 100%;
  display: inline-block;
  width: 50%;
  font-size: 14px;
}
.multiplevideolist {
  /* height: 100%; */
  width: 100%;
}

.waiting-label {
  width: 100%;
  color: #797979;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.userslide {
  width: 100%;
  padding-top: 15px;
}
.owl-carousel .owl-stage {
  margin: auto;
}
.userslide .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  left: -14px;
  top: 30%;
  width: 30px;
  height: 30px;
  color: transparent;
  background-color: #0ca2f9;
  border-radius: 50%;
  background-image: url(../public/assets/images/left-arrow.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
  text-align: center;
  outline: none;
}
.userslide .owl-carousel .owl-nav button.owl-prev.disabled,
.userslide .owl-carousel .owl-nav button.owl-next.disabled {
  opacity: 0.7;
  cursor: no-drop;
}
.userslide .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  right: -14px;
  top: 30%;
  width: 30px;
  height: 30px;
  color: transparent;
  background-color: #0ca2f9;
  border-radius: 50%;
  background-image: url(../public/assets/images/right-arrow.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
  text-align: center;
  outline: none;
}
/********/
#singlevideo,
#doublevideo,
#multiplevideo {
  display: none;
}
#singlevideo.active,
#doublevideo.active,
#multiplevideo.active {
  display: block;
}

.streamuseroption {
  position: absolute;
  /* left: 0; */
  top: 0;
  /* width: 100%; */
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  padding: 3px;
  border-radius: 10px 0 0 0;
}
.streamuseroption a {
  color: #fff;
  margin: 0 10px;
  position: relative;
}
.streamuseroption span {
  color: #fff;
}
.streamuseroption a.stop:after,
.streamuseroption a.stop:after {
  background: url(../public/assets/images/stop-icon.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  content: "";
  left: -4px;
  top: -3px;
  z-index: 1;
}
.left-pnl {
  padding: 50px 0;
}
.left-pnl .logo {
  margin-bottom: 50px;
}
.left-pnl .page-text h2,
.left-pnl .page-text p {
  color: #fff;
}
.right-pnl {
  padding: 50px 0;
}
.right-pnl .video-room {
  height: 300px;
  width: 100%;
  background: #f2f2f2;
  position: relative;
}
.video-poster {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url("../public/assets/images/user-screen.jpg");
  background-size: 70%;
  background-position: bottom;
}
.onoffswitch {
  position: relative;
  width: 78px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #232323;
  border-radius: 20px;
  margin: 0;
}
.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #cd056e;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  background-color: #eeeeee;
  color: #999999;
  text-align: right;
}
.onoffswitch-switch {
  width: 26px;
  margin: 2px;
  background: #ffffff;
  border: 2px solid #fff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
.video-opt .video-menu h6 {
  padding-top: 5px;
}
.video-opt .video-menu .btn-new {
  background: #0d74cb;
  color: #fff;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 12px;
}
.video-opt .field-name .form-control {
  background: #232323;
  color: #fff;
  border: 1px solid #fff;
  margin: 5px 0;
  border-radius: 0;
}
.join-box {
  margin-top: 30px;
}
.join-box .btn-join {
  background: #0d74cb;
  color: #fff;
  padding: 8px 20px;
  font-weight: 500;
  border-radius: 20px;
  font-size: 14px;
}
.join-box .btn-host {
  background: #cd056e;
  color: #fff;
  padding: 8px 20px;
  font-weight: 500;
  border-radius: 20px;
  font-size: 14px;
}
.feedbacklink {
  margin-top: 20px;
}
.feedbacklink a {
  color: #fff;
  text-decoration: none;
}
.selectoption {
  font-size: 13px;
  width: 150px;
  height: 30px;
  border-radius: 30px;
  padding: 0 14px;
  box-sizing: border-box;
  background: #343a40;
  color: #fff;
  outline: none;
  border: 1px solid #6b6b6b;
  margin-left: 5px;
}
.modalfeedback .modal-header {
  background: #0d74cb;
  color: #fff;
}
.modal-title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
}
.modalfeedback .modal-body textarea {
  width: 100%;
  border: 1px solid #e8e8e8;
  outline: none;
  min-height: 120px;
  font-size: 15px;
  font-weight: 400;
  padding: 10px;
}
.modalfeedback .form-control {
  border: 1px solid #e8e8e8;
  outline: none;
  font-size: 15px;
  border-radius: 0;
}
.privacytext {
  padding: 10px;
  background: #f1f1f1;
  margin-top: 10px;
}
.privacytext p {
  font-size: 12px;
  margin: 0;
}
.modalfeedback .modal-footer .btn {
  font-size: 14px;
  border: 0;
  border-radius: 30px;
  padding: 8px 10px;
  min-width: 110px;
}
.modalfeedback .btn-danger {
  background: #cd056e;
}
.modalfeedback .btn-primary {
  background: #0d74cb;
}

</style>